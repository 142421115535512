/**
 * Detects the type of credit card based on its number.
 * @param cardNumber - The credit card number string to check.
 * @returns A string representing the detected card type, or an empty string if not recognized.
 */
export const detectCardType = (cardNumber: string): string => {
  const trimmedCardNumber = cardNumber.trim();
  if (/^4/.test(trimmedCardNumber)) {
    return "visa";
  } else if (/^5[1-5]/.test(trimmedCardNumber)) {
    return "mastercard";
  } else if (/^3[47]/.test(trimmedCardNumber)) {
    return "amex";
  } else if (/^6(?:011|5)/.test(trimmedCardNumber)) {
    return "discover";
  } else if (/^3(?:0[0-5]|[68])/.test(trimmedCardNumber)) {
    return "diners";
  } else if (/^62/.test(trimmedCardNumber)) {
    return "unionpay";
  } else if (/^35/.test(trimmedCardNumber)) {
    return "jcb";
  }
  return "";
}

/**
 * Formats a time duration from milliseconds to minutes and seconds.
 * @param ms - The time duration in milliseconds.
 * @returns A formatted string representing the time in minutes and seconds.
 */
export const formatTime = (ms: number): string => {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return `${minutes}m ${seconds}s`;
};

/**
 * Validates the email format.
 * @param email - The email string to validate.
 * @returns A boolean indicating whether the email is valid.
 */
export const validateEmail = (email: string): boolean => {
  const trimmedEmail = email.trim();
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (trimmedEmail.toLowerCase().includes('gmail') && !trimmedEmail.toLowerCase().endsWith('gmail.com')) {
    return false;
  }
  return re.test(trimmedEmail);
};

/**
 * Validates the phone number format.
 * @param phone - The phone number string to validate.
 * @returns A boolean indicating whether the phone number is valid.
 */
export const validatePhoneNumber = (phone: string): boolean => {
  const trimmedPhone = phone.trim();
  const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
  return phoneRegex.test(trimmedPhone);
};

/**
 * Validates the credit card number using the Luhn algorithm.
 * @param number - The credit card number string to validate.
 * @returns An object containing the validation result and detected card type.
 */
export const validateCreditCard = (number: string): boolean => {
  const trimmedNumber = number.trim();
  const digits = trimmedNumber.replace(/\D/g, '');
  let sum = 0;
  let isEven = false;

  for (let i = digits.length - 1; i >= 0; i--) {
    let digit = parseInt(digits[i], 10);

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isEven = !isEven;
  }

  return (sum % 10 === 0) && (digits.length >= 13 && digits.length <= 19);
};

/**
 * Validates the card expiry date.
 * @param expiry - The expiry date string in "MM / YY" format.
 * @returns A boolean indicating whether the expiry date is valid.
 */
export const validateCardExpiry = (expiry: string): boolean => {
  const trimmedExpiry = expiry.trim();
  const [month, year] = trimmedExpiry.split('/').map(part => part.trim());
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100;
  const currentMonth = currentDate.getMonth() + 1;

  const expiryMonth = parseInt(month, 10);
  const expiryYear = parseInt(year, 10);

  if (isNaN(expiryMonth) || isNaN(expiryYear) || expiryMonth < 1 || expiryMonth > 12) {
    return false;
  }

  if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth < currentMonth)) {
    return false;
  }

  return true;
};

/**
 * Validates the CVC code.
 * @param cvc - The CVC string to validate.
 * @returns A boolean indicating whether the CVC is valid.
 */
export const validateCVC = (cvc: string): boolean => {
  const trimmedCVC = cvc.trim();
  // CVC should be 3 or 4 digits
  return /^[0-9]{3,4}$/.test(trimmedCVC);
};