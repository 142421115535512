import React from 'react';

const PrivacyPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-start min-h-screen p-10 font-sans overflow-auto">
      <div className="flex flex-col w-full max-w-4xl">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>

        <p className="mb-4">
          First of all, thank you for trusting us with your data. We take that responsibility very seriously. We're committed to protecting your data, and here's how we do it.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">Information We Collect</h2>
        <p className="mb-3">
          We collect information to make our services better for you. Here's what we gather:
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">Personal Information You Provide to Us</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li><span className='font-medium'>Account Information</span>: Your name, email address, password, and other registration details.</li>
          <li><span className='font-medium'>Payment Information</span>: Credit card details, billing address, and transaction history.</li>
          <li><span className='font-medium'>Contact Information</span>: Phone number, mailing address, and other contact details.</li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-3">How We Use Your Information</h2>
        <p className="mb-3">
          Here's how we make use of the information we collect to improve your experience:
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">1. Providing and Maintaining Our Services</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Process transactions and manage your account.</li>
          <li>Deliver customer support and respond to your inquiries.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">2. Improving Our Services</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Analyze how you use our services to enhance functionality and user experience.</li>
          <li>Develop new features based on your feedback and needs.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">3. Security and Fraud Prevention</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Protect against unauthorized access, fraud, and other malicious activities.</li>
          <li>Monitor and analyze usage to keep our platform secure.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">4. Compliance and Legal Obligations</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Meet legal requirements and respond to lawful requests.</li>
          <li>Enforce our terms, policies, and agreements.</li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-3">Data Sharing and Disclosure</h2>
        <p className="mb-3">
          We might share your information with others under specific circumstances:
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">1. Service Providers</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>We work with trusted third-party vendors who help us with payment processing, data analysis, email delivery, and customer support.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">2. Business Transfers</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>If we're involved in a merger, acquisition, or sale of assets, your information might be part of that transaction.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">3. Legal Requirements</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>We may disclose your information if required by law, regulation, or legal process.</li>
          <li>Protect our rights, property, and safety, as well as those of our users and the public.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">4. With Your Consent</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Share information for any purpose with your explicit consent.</li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-3">Data Security</h2>
        <p className="mb-4">
          We take your data's security seriously. Here's what we do to protect it:
        </p>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li><strong>Encryption</strong>: We encrypt your data both in transit and at rest.</li>
          <li><strong>Access Controls</strong>: Only authorized personnel can access your personal data.</li>
          <li><strong>Regular Audits</strong>: We conduct regular security assessments and audits.</li>
          <li><strong>Incident Response</strong>: We have procedures in place to address data breaches and security incidents promptly.</li>
        </ul>
        <p className="mb-4">
          While we strive to protect your information, no method of transmission over the internet or electronic storage is completely secure. We do our best to use commercially acceptable means to keep your data safe, but we can't guarantee absolute security.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">Your Rights and Choices</h2>
        <p className="mb-3">
          Depending on where you live, you might have the following rights regarding your personal data:
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">1. Access and Portability</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Request access to your personal data and obtain a copy for your records.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">2. Correction</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Ask us to correct any inaccurate or incomplete information.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">3. Deletion</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Request the deletion of your personal data under certain conditions.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">4. Restriction of Processing</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Ask us to limit how we use your data under specific circumstances.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">5. Objection</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Object to the processing of your data for certain purposes, including direct marketing.</li>
        </ul>

        <h3 className="text-lg font-semibold mt-4 mb-2">6. Data Portability</h3>
        <ul className="list-disc list-inside mb-4 space-y-2">
          <li>Receive your data in a structured, commonly used, and machine-readable format.</li>
        </ul>
        <p className="mb-4">
          To exercise these rights, please reach out to us using the contact information provided below.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">Children's Privacy</h2>
        <p className="mb-4">
          Our services aren't intended for individuals under 18. We don't knowingly collect personal information from children. If we find out that we've accidentally gathered such information, we'll delete it right away.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy occasionally to reflect changes in our practices or legal requirements. We'll notify you of significant changes by posting the new policy on our website and updating the effective date. Continuing to use our services after changes take effect means you accept the updated Privacy Policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-3">Contact Us</h2>
        <p className="mb-3">
          If you have any questions or concerns about this Privacy Policy or how we handle your data, feel free to reach out to us at:
        </p>
        <p className="mb-1"><strong>Email:</strong> <a href="mailto:support@ghostpay.app" className="text-blue-500 underline">support@ghostpay.app</a></p>
        <p className="mb-1"><strong>Address:</strong> 1 Western Ave #440, Boston, MA 02163</p>
      </div>
    </div>
  );
};

export default PrivacyPage;