import CustomerPage from 'components/pages/CustomerPage';
import PageNotFound from 'components/pages/PageNotFound';
import PrivacyPage from 'components/pages/PrivacyPage';
import SalespersonPage from 'components/pages/SalespersonPage';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './style.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/crunch/max-voler" element={<CustomerPage />} />
        <Route path="/access/:token" element={<SalespersonPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/crunch/max-voler" replace />} />
      </Routes>
    </Router>
  );
}

export default App;