import { GymTags } from 'models/GymTags';
import { SalespersonTags } from 'models/SalespersonTag';
import { AxiosRes, BaseRes, api } from './api';

export const submitInformation = async (args: ShareInformationReq): Promise<ShareInformationRes> => {
  return await api.post('/share', args);
};

export const fetchInformation = async (args: AccessInformationReq): Promise<AccessInformationRes> => {
  return await api.post('/access', args);
};

/**
 * POST /share
 */
export type ShareInformationReq = {
  salespersonTag: SalespersonTags;
  gymTag: GymTags.CRUNCH;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
}

export type ShareInformationRes = AxiosRes & BaseRes;

/**
 * POST /access
 */
export type AccessInformationReq = {
  token: string;
}

export type AccessInformationRes = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
  timeRemaining: number;
} & AxiosRes & BaseRes;