import amexIcon from "assets/amexIcon.svg";
import crunchLogo from "assets/crunchLogo.svg";
import dinersIcon from "assets/dinersIcon.svg";
import discoverIcon from "assets/discoverIcon.svg";
import errorIcon from "assets/errorIcon.svg";
import jcbIcon from "assets/jcbIcon.svg";
import loadingIcon from "assets/loadingIcon.svg";
import mailIcon from "assets/mailIcon.svg";
import mastercardIcon from "assets/mastercardIcon.svg";
import shieldIcon from "assets/shieldIcon.svg";
import unionpayIcon from "assets/unionpayIcon.svg";
import usFlagIcon from "assets/usFlagIcon.svg";
import visaIcon from "assets/visaIcon.svg";
import Modal from 'components/modal';
import {
  detectCardType,
  validateCardExpiry,
  validateCreditCard,
  validateCVC,
  validateEmail,
  validatePhoneNumber
} from "components/utils";
import { GymTags } from "models/GymTags";
import { SalespersonTags } from "models/SalespersonTag";
import React, { useState } from 'react';
import { submitInformation } from "server";

const CustomerPage = (): JSX.Element => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardCVCError, setCardCVCError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardType, setCardType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const isInputDisabled = isSubmitting || isModalOpen;

  const handleCardExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      const month = value.slice(0, 2);
      const year = value.slice(2);
      if (value.length > 2) {
        setCardExpiry(`${month} / ${year}`);
      } else {
        setCardExpiry(month);
      }
    }
    if (cardExpiryError) setCardExpiryError("");
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardNumberError("");
    const input = e.target.value.replace(/\D/g, '');
    const formattedInput = input.replace(/(\d{4})(?=\d)/g, '$1 ');
    const trimmedInput = formattedInput.slice(0, 19);

    let cursorPosition = e.target.selectionStart;
    if (cursorPosition !== null) {
      if (trimmedInput.length > cardNumber.length && trimmedInput.charAt(cursorPosition - 1) === ' ') {
        cursorPosition += 1;
      }
    }
    setCardNumber(trimmedInput);

    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    const cardType = detectCardType(trimmedInput);
    setCardType(cardType);
  };

  const handleCardCVCChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    setCardCVC(input);
    if (cardCVCError) setCardCVCError("");
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';

    if (input.length > 0) {
      formattedNumber = '(' + input.slice(0, 3);
      if (input.length > 3) {
        formattedNumber += ') ' + input.slice(3, 6);
        if (input.length > 6) {
          formattedNumber += '-' + input.slice(6, 10);
        }
      }
    }

    setPhoneNumber(formattedNumber);

    if (phoneError) setPhoneError("");
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneNumberBlur = () => {
    if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleCardNumberBlur = () => {
    const isValid = validateCreditCard(cardNumber);
    if (cardNumber && !isValid) {
      setCardNumberError("Please enter a valid credit card number");
    } else {
      setCardNumberError("");
    }
  };

  const handleCardExpiryBlur = () => {
    if (cardExpiry && !validateCardExpiry(cardExpiry)) {
      setCardExpiryError("Invalid expiry date");
    } else {
      setCardExpiryError("");
    }
  };

  const handleCardCVCBlur = () => {
    if (cardCVC && !validateCVC(cardCVC)) {
      setCardCVCError("Invalid CVC");
    } else {
      setCardCVCError("");
    }
  };

  const handleSubmit = async () => {
    // Reset previous errors
    setSubmitError("");
    setEmailError("");
    setPhoneError("");
    setCardNumberError("");
    setCardExpiryError("");
    setCardCVCError("");

    // Validate First Name
    if (!firstName) {
      setSubmitError("First name is required");
      return;
    }

    // Validate Last Name
    if (!lastName) {
      setSubmitError("Last name is required");
      return;
    }

    // Validate Email
    if (!email) {
      setSubmitError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      setSubmitError("Please enter a valid email address");
      return;
    }

    // Validate Phone Number
    if (!phoneNumber) {
      setSubmitError("Phone number is required");
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      setSubmitError("Please enter a valid phone number");
      return;
    }

    // Validate Card Number
    if (!cardNumber) {
      setSubmitError("Credit card number is required");
      return;
    }

    if (!validateCreditCard(cardNumber)) {
      setCardNumberError("Please enter a valid credit card number");
      setSubmitError("Please enter a valid credit card number");
      return;
    }

    // Validate Card Expiry
    if (!cardExpiry) {
      setSubmitError("Card expiry date is required");
      return;
    }
    if (!validateCardExpiry(cardExpiry)) {
      setCardExpiryError("Invalid expiry date");
      setSubmitError("Invalid expiry date");
      return;
    }

    // Validate CVC
    if (!cardCVC) {
      setSubmitError("CVC is required");
      return;
    }
    if (!validateCVC(cardCVC)) {
      setCardCVCError("Invalid CVC");
      setSubmitError("Invalid CVC");
      return;
    }

    // If all validations pass, proceed to submit
    setIsSubmitting(true);
    try {
      const res = await submitInformation({
        salespersonTag: SalespersonTags.MAX_VOLER,
        gymTag: GymTags.CRUNCH,
        firstName,
        lastName,
        email,
        phoneNumber,
        cardNumber,
        cardExpiry,
        cardCVC,
      });

      if (res.success) {
        setIsModalOpen(true);
      } else {
        setSubmitError("Card information not submitted. Please try again");
      }
    } catch (error) {
      setSubmitError("An unexpected error occurred. Please try again");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={`overflow-y-auto w-screen flex flex-col ${isModalOpen ? 'blur-sm' : ''}`}>
        <div className="w-full p-3 flex items-center justify-center [background:linear-gradient(180deg,rgb(210,2,36)_0%,rgb(255,146,71)_100%)]">
          <img src={crunchLogo} alt="Crunch" className="w-48 h-48 min-w-40 min-h-40" />
        </div>
        <div className="flex-grow flex flex-col p-10 justify-top items-center bg-[#F6F5F3] w-full overflow-y-auto">
          <div className="flex flex-col max-w-[500px] min-w-[250px] w-full">
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px] font-normal font-[400]" style={{ fontWeight: "400" }}>First Name</label>
              <input
                className="h-[50px] bg-white rounded-md shadow border border-[#bfbfbf] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 p-3 font-light text-black font-300"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={isInputDisabled}
                style={{ color: "black" }}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px] font-normal font-[400]" style={{ fontWeight: "400" }}>Last Name</label>
              <input
                className="h-[50px] bg-white rounded-md shadow border border-[#bfbfbf] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 p-3 font-light text-black font-300"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={isInputDisabled}
                style={{ color: "black" }}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px] font-normal font-[400]" style={{ fontWeight: "400" }}>Contact Information</label>
              <div className="relative">
                <input
                  className={`h-[50px] w-full bg-white rounded-t-md rounded-bl-none rounded-br-none border ${emailError ? 'border-red-500' : 'border-[#bfbfbf]'} border-b-[0.5px] outline-none focus:border-[#FF9247] transition-all duration-200 pl-10 pr-8 font-light text-black font-300`}
                  placeholder="email@example.com"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value); if (emailError) setEmailError(""); }}
                  onBlur={handleEmailBlur}
                  disabled={isInputDisabled}
                  style={{ color: "black" }}
                />
                <img src={mailIcon} alt="email" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                {emailError && (
                  <img src={errorIcon} alt="error" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                )}
              </div>
              <div className="relative">
                <input
                  className={`h-[50px] w-full bg-white rounded-b-md rounded-tl-none rounded-tr-none shadow border ${phoneError ? 'border-red-500' : 'border-[#bfbfbf]'} border-t-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-10 pr-8 font-light text-black font-300`}
                  placeholder="(800) 555-0175"
                  value={phoneNumber}
                  onChange={(e) => { handlePhoneNumberChange(e); if (phoneError) setPhoneError(""); }}
                  onBlur={handlePhoneNumberBlur}
                  maxLength={14}
                  disabled={isInputDisabled}
                  style={{ color: "black" }}
                />
                <img src={usFlagIcon} alt="Phone Number" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                {phoneError && (
                  <img src={errorIcon} alt="error" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                )}
              </div>
              {(emailError || phoneError) && <p className="text-red-500 text-xs mt-1">{emailError || phoneError}</p>}
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px] font-normal font-[400]" style={{ fontWeight: "400" }}>Card Information</label>
              <div className="relative">
                <input
                  className={`h-[50px] w-full bg-white rounded-t-md border ${cardNumberError ? 'border-red-500' : 'border-[#bfbfbf]'} border-b-[0.5px] outline-none focus:border-[#FF9247] transition-all duration-200 pl-3 pr-12 font-light text-black font-300`}
                  placeholder="1234 1234 1234 1234"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  onBlur={handleCardNumberBlur}
                  maxLength={19}
                  disabled={isInputDisabled}
                  style={{ color: "black" }}
                />
                <div className="flex items-center absolute right-3 top-1/2 transform -translate-y-1/2">
                  {cardNumberError && (
                    <img src={errorIcon} alt="error" className="w-4 h-4" />
                  )}
                  {cardType === "" && !cardNumberError && (
                    <div className="flex items-center">
                      <img src={visaIcon} alt="Visa" className="mr-1" />
                      <img src={mastercardIcon} alt="Mastercard" className="mr-1" />
                      <img src={amexIcon} alt="American Express" className="mr-1" />
                      <img src={discoverIcon} alt="Discover" className="mr-1" />
                    </div>
                  )}
                  {cardType === "visa" && !cardNumberError && <img src={visaIcon} alt="Visa" className="w-8 h-5" />}
                  {cardType === "mastercard" && !cardNumberError && <img src={mastercardIcon} alt="Mastercard" className="w-8 h-5" />}
                  {cardType === "amex" && !cardNumberError && <img src={amexIcon} alt="American Express" className="w-8 h-5" />}
                  {cardType === "discover" && !cardNumberError && <img src={discoverIcon} alt="Discover" className="w-8 h-5" />}
                  {cardType === "diners" && !cardNumberError && <img src={dinersIcon} alt="Diners Club" className="w-8 h-5" />}
                  {cardType === "unionpay" && !cardNumberError && <img src={unionpayIcon} alt="UnionPay" className="w-8 h-5" />}
                  {cardType === "jcb" && !cardNumberError && <img src={jcbIcon} alt="JCB" className="w-8 h-5" />}
                </div>
              </div>
              <div className="flex">
                <div className="relative w-1/2">
                  <input
                    className={`h-[50px] w-full bg-white rounded-bl-md border ${cardExpiryError ? 'border-red-500' : 'border-[#bfbfbf]'} border-t-[0.5px] border-r-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-3 pr-8 font-light text-black font-300`}
                    placeholder="MM / YY"
                    onChange={handleCardExpiryChange}
                    onBlur={handleCardExpiryBlur}
                    maxLength={7}
                    value={cardExpiry}
                    disabled={isInputDisabled}
                    style={{ color: "black" }}
                  />
                  {cardExpiryError && (
                    <img src={errorIcon} alt="error" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                  )}
                </div>
                <div className="relative w-1/2">
                  <input
                    className={`h-[50px] w-full bg-white rounded-br-md border ${cardCVCError ? 'border-red-500' : 'border-[#bfbfbf]'} border-t-[0.5px] border-l-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-3 pr-8 font-light text-black font-300`}
                    placeholder="CVC"
                    maxLength={4}
                    value={cardCVC}
                    onChange={handleCardCVCChange}
                    onBlur={handleCardCVCBlur}
                    disabled={isInputDisabled}
                    style={{ color: "black" }}
                  />
                  {cardCVCError && (
                    <img src={errorIcon} alt="error" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4" />
                  )}
                </div>
              </div>
              {(cardNumberError || cardExpiryError || cardCVCError) && (
                <p className="text-red-500 text-xs mt-1">{cardNumberError || cardExpiryError || cardCVCError}</p>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <button
                className="h-[50px] bg-[#d20224] rounded-md shadow-md text-white text-base font-normal font-['Inter'] leading-none flex items-center justify-center relative"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                <div className="absolute left-0 right-0 flex items-center justify-center">
                  <span className="select-none font-inter">{isSubmitting ? "Processing..." : "Submit"}</span>
                </div>
                <div className="flex-grow"></div>
                <img
                  src={isSubmitting ? loadingIcon : shieldIcon}
                  alt={isSubmitting ? "Processing..." : "Secure"}
                  className={`w-5 h-5 mr-5 ${isSubmitting ? 'animate-spin' : ''} ml-2 select-none`}
                />
              </button>
              {submitError && (
                <p className="text-red-500 text-sm mt-2 text-center">{submitError}</p>
              )}
              <div className="text-xs text-gray-500 mt-5 flex justify-center items-center w-full">
                <div className="flex items-center">
                  <span>Secured by GhostPay</span>
                  <span className="mx-2">•</span>
                  <a href="/privacy" className="text-gray-500 hover:underline">Privacy</a>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <Modal title="Information sent" body="Your payment information has been uploaded. You will receive a charge shortly." icon="success" />
        </div>
      )}
    </>
  );
};
export default CustomerPage;
